<template>
  <div>
    <div v-show="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-wrap gap-8 align-items-center justify-between">
          <h3 class="box-title">Trade Flex</h3>
          <div v-if="tab === 'search'" class="flex flex-wrap gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <select v-model="status" class="form-control" aria-label="" style="width:200px">
              <option value="">TradeFlex Status - All</option>
              <option value="1">Open</option>
              <option value="2">Fulfilled</option>
              <option value="3">Cancelled</option>
            </select>
            <v-select v-model="currency" :clearable="false" :options="markets" :reduce="m => m.ID" label="Name"
              style="min-width: 120px;" taggable></v-select>
            <v-select v-model="userId" :clearable="true" :options="merchants" :reduce="m => m.ID"
              placeholder="All Merchants" label="Name" style="min-width: 320px;" taggable></v-select>
            <v-select v-model="altCoinID" :clearable="true" :options="formattedAltCoins" :reduce="a => a.ID"
              placeholder="All Coins" label="Name" style="min-width: 230px;" taggable></v-select>
            <v-select v-model="tft" :clearable="true" :options="tftList" :reduce="t => t.id" placeholder="All Types"
              label="label" style="min-width: 100px;" taggable></v-select>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." ref="searchInput"
              @keydown.enter="getPaginatedRecords(1, false)" style="width: 250px;" type="text" />
            <button class="btn btn-primary btn-flat btn-sm" @click="getPaginatedRecords(1, false)">Search
            </button>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <va-tabs @selectedTab="selectedTab">
          <div id="latest" slot="content" :data-title="'Last 1000 Actions'" class="active">
            <trade-flex-listing :records="latestRecords" @update="updateRecords"></trade-flex-listing>
          </div>
          <div id="search" slot="content" :data-title="'Search Results'">
            <trade-flex-search-listing :records="records" :meta="meta" @updatePage="updatePage" @sort="sort"
              @update="updateRecords"></trade-flex-search-listing>
          </div>
        </va-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import VaTabs from "@/components/VATabs";
import TradeFlexListing from "@/components/tradeflex/TradeFlexListing";
import { mapGetters } from "vuex";
import { LIST_MERCHANTS, START_LOADING, STOP_LOADING } from "@/store/keys";
import { saveNotification } from "@/lib/utils";
import TradeFlexSearchListing from "../components/tradeflex/TradeFlexSearchListing";

export default {
  name: "TradeFlex",
  components: {
    TradeFlexSearchListing,
    VaTabs,
    TradeFlexListing,
  },
  data() {
    return {
      latestRecords: [],
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
      userId: null,
      altCoinID: null,
      currency: "btc",
      tft: null,
      id: "",
      status: "",
      tab: "latest",
      tftList: [
        { id: 1, label: "Buy" },
        { id: 2, label: "Sell" },
      ],
      markets: [
        { ID: "btc", Name: "Bitcoin" },
        { ID: "ltc", Name: "Litecoin" },
        { ID: "dog", Name: "Dogecoin" },
        { ID: "erx", Name: "EURx" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
      "merchants",
      "altCoins",
    ]),
    formattedAltCoins() {
      return this.altCoins.map(a => {
        return {
          ...a,
          Name: `${a.Name} (${a.LongName})`,
        };
      });
    },
  },
  watch: {
    $route(to, from) {
      this.id = "";
      if (to.query.id !== from.query.id && to.query.merchant_id !== from.query.merchant_id) {
        if (to.query.merchant_id) {
          this.userId = to.query.merchant_id;
        }
        if (to.query.id) {
          this.id = to.query.id;
        }
        this.getPaginatedRecords();
      }
      const hash = this.$route.hash;
      if (hash) {
        const [h, q] = hash.split("?");
        this.tab = h.replace("#", "");
        setTimeout(() => {
          $("[href=\"" + h + "\"]").tab("show");
        }, 500);
        if (q) {
          const query = {};
          q.split("&").forEach(function (keyValue) {
            const [key, value] = keyValue.split("=");
            query[key] = decodeURIComponent(value);
          });
          if (Object.keys(query).length > 0) {
            if (query.altCoinId !== undefined) {
              this.altCoinID = query.altCoinId;
            }
            if (query.merchant_id !== undefined) {
              this.userId = query.merchant_id;
            }
          }
        }
        if (h === "#search") {
          this.getPaginatedRecords();
        } else {
          this.getLatestRecords();
        }
      } else {
        this.getLatestRecords();
      }
    },
    currency(newVal, oldVal) {
      if (newVal !== oldVal) {
        const type = this.getTradeFlexTable(newVal);
        this.$store.dispatch(LIST_MERCHANTS, { type });
      }
    },
  },
  methods: {
    getTradeFlexTable(val) {
      const mList = {
        btc: "TradeFlex",
        dog: "TradeFlexDoge",
        erx: "TradeFlexEurx",
        ltc: "TradeFlexLTC",
      };
      return mList[val];
    },
    selectedTab(id) {
      this.tab = id;
      if (id === "latest") {
        this.getLatestRecords(false);
      } else {
        this.getPaginatedRecords(this.meta.page, false);
      }
    },
    showLoading() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoading() {
      this.$swal.close();
    },
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedRecords(1, false);
    },
    getLatestRecords(isLoading = true) {
      if (isLoading) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=trade_flex_latest`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.latestRecords = response.data.data;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getPaginatedRecords(page = 1, isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      } else {
        this.id = "";
        this.searching = "Searching...";
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=trade_flex`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          merchantID: this.userId || null,
          id: this.id,
          altCoinID: this.altCoinID || null,
          tft: this.tft || null,
          currency: this.currency || "btc",
          status: this.status,
          limit: Number.parseInt(this.meta.limit),
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    updateRecords(type) {
      setTimeout(() => {
        if (type === "latest") {
          this.getLatestRecords(false);
        } else {
          this.getPaginatedRecords(this.meta.page, false);
        }
      }, 1500);
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.sortKey = "CreatedOn";
    this.order = "desc";
    this.$store.dispatch(LIST_MERCHANTS, { type: "TradeFlex" });
    window.addEventListener("keydown", this.handleSlashKey);
    const hash = this.$route.hash;
    if (hash) {
      const [h, q] = hash.split("?");
      this.tab = h.replace("#", "");
      setTimeout(() => {
        $("[href=\"" + h + "\"]").tab("show");
      }, 500);
      if (q) {
        const query = {};
        q.split("&").forEach(function (keyValue) {
          const [key, value] = keyValue.split("=");
          query[key] = decodeURIComponent(value);
        });
        if (Object.keys(query).length > 0) {
          if (query.altCoinId !== undefined) {
            this.altCoinID = query.altCoinId;
          }
          if (query.tft !== undefined) {
            this.tft = Number(query.tft);
          }
        }
      }
      if (h === "#search") {
        this.getPaginatedRecords();
      } else {
        this.getLatestRecords();
      }
    } else {
      this.getLatestRecords();
    }
    if (Object.keys(this.$route.query).length > 0 && this.$route.query?.q) {
      this.keyword = this.$route.query.q;
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div v-if="meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-condensed mb-0">
        <thead>
          <tr>
            <th class="pointer" @click="sort('ID')">
              ID
              <span v-if="sortKey === 'ID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer">
              Market
            </th>
            <th class="pointer" @click="sort('MerchantID')">
              Merchant
              <span v-if="sortKey === 'MerchantID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th>Traded With</th>
            <th class="pointer" @click="sort('AltCoinID')">
              AltCoin
              <span v-if="sortKey === 'AltCoinID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('TradeFlexTypeID')">
              TradeFlex Type
              <span v-if="sortKey === 'TradeFlexTypeID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('TradeFlexStatusID')">
              TradeFlex Status
              <span v-if="sortKey === 'TradeFlexStatusID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Amount')">
              Amount
              <span v-if="sortKey === 'Amount'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('ExchangedAmount')">
              Exchanged Amount
              <span v-if="sortKey === 'ExchangedAmount'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Price')">
              Price
              <span v-if="sortKey === 'Price'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('ExchangedPrice')">
              Exchanged Price
              <span v-if="sortKey === 'ExchangedPrice'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('MatchState')">
              MatchState
              <span v-if="sortKey === 'MatchState'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Child')">
              Child
              <span v-if="sortKey === 'Child'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('BuyParent')">
              Buy Parent
              <span v-if="sortKey === 'BuyParent'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('SellParent')">
              Sell Parent
              <span v-if="sortKey === 'SellParent'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Cancel')">
              Cancel
              <span v-if="sortKey === 'Cancel'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('CreatedOn')">
              Created On
              <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('ConfirmedOn')">
              Confirmed On
              <span v-if="sortKey === 'ConfirmedOn'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="paginatedRecords.length > 0">
            <tr v-for="(record, index) in paginatedRecords" :key="index">
              <td>{{ record.ID }}</td>
              <td>{{ tableToMarket(record.Tbl) }}</td>
              <td class="no-wrap">
                <router-link :to="{ name: 'user-detail', params: { id: record.MerchantID } }" tag="a">
                  {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                </router-link>
              </td>
              <td class="no-wrap">
                <router-link v-if="record.TradedWithID"
                  :to="{ name: 'user-detail', params: { id: record.TradedWithID } }" tag="a">
                  {{ record.TradedWithShopTitle + ' (' + record.TradedWithOwnerNickName + ')' }}
                </router-link>
              </td>
              <td class="no-wrap">
                {{ record.CoinName + ' (' + record.LongName + ')' }}
              </td>
              <td class="no-wrap">{{ record.TradeFlexTypeID === 1 ? 'Buy' : 'Sell' }}</td>
              <td class="no-wrap">{{ tradeFlexStatus(record.TradeFlexStatusID) }}</td>
              <td class="no-wrap">{{ toFixed(record.Amount) }}</td>
              <td class="no-wrap">{{ toFixed(record.ExchangedAmount) }}</td>
              <td class="no-wrap">{{ toFixed(record.Price) }}</td>
              <td class="no-wrap">{{ toFixed(record.ExchangedPrice) }}</td>
              <td class="no-wrap">
                <span v-tooltip="{ content: tradeFlexMatchStates(record.MatchState), trigger: 'click hover focus' }"
                  class="pointer">
                  {{ record.MatchState }}
                </span>
              </td>
              <td class="no-wrap">{{ record.Child }}</td>
              <td class="no-wrap">
                <router-link
                  :to="{ name: 'trade-flex', query: { id: record.BuyParent, merchant_id: (record.TradeFlexTypeID === 1 ? record.MerchantID : record.TradedWithID) } }"
                  tag="a">
                  {{ record.BuyParent }}
                </router-link>
              </td>
              <td class="no-wrap">
                <router-link
                  :to="{ name: 'trade-flex', query: { id: record.SellParent, merchant_id: (record.TradeFlexTypeID === 2 ? record.MerchantID : record.TradedWithID) } }"
                  tag="a">
                  {{ record.SellParent }}
                </router-link>
              </td>
              <td class="no-wrap">{{ record.Cancel }}</td>
              <td class="no-wrap">{{ formatDate(record.CreatedOn) }}</td>
              <td class="no-wrap">{{ formatDate(record.ConfirmedOn) }}</td>
              <td class="action-sm pr-16">
                <button v-if="record.TradeFlexStatusID === 1" class="btn btn-danger btn-flat btn-sm"
                  @click="cancelTrade(record, index)">Cancel Trade
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="19">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { formatDate, toFixed, saveNotification } from "@/lib/utils";
import * as _ from "lodash";

export default {
  name: "TradeFlexListing",
  props: ["records"],
  components: {
    Pagination,
  },
  data() {
    return {
      sortKey: "CreatedOn",
      order: "desc",
      currentPage: 1,
      limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
    };
  },
  computed: {
    meta() {
      return {
        total: this.records.length,
        page: this.currentPage,
        offset: (this.currentPage - 1) * this.limit,
        limit: this.limit,
      };
    },
    paginatedRecords() {
      let rawRecords = JSON.parse(JSON.stringify(this.records));
      rawRecords = _.orderBy(rawRecords, [this.sortKey], [this.order]);
      if (rawRecords.length > 0) {
        const offset = this.meta.page > 0 ? this.meta.page - 1 : 0;
        return rawRecords.slice(offset * this.meta.limit, this.meta.page * this.meta.limit);
      }
      return rawRecords;
    },
  },
  methods: {
    showLoading() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoading() {
      this.$swal.close();
    },
    updatePage(page) {
      this.currentPage = page;
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
    },
    formatDate(val) {
      return formatDate(val);
    },
    toFixed(num) {
      return toFixed(num);
    },
    tableToMarket(tbl) {
      const tbls = {
        TradeFlex: "BTC", TradeFlexDoge: "DOGE", TradeFlexEurx: "EURx", TradeFlexLTC: "LTC",
      };
      return tbls[tbl] || "BTC";
    },
    tradeFlexStatus(val) {
      let label = "";
      switch (val) {
        case 1:
          label = "Open";
          break;
        case 2:
          label = "Fulfilled";
          break;
        case 3:
          label = "Cancelled";
          break;
      }
      return label;
    },
    tradeFlexMatchStates(state) {
      let label = "";
      switch (state) {
        case 1:
          label = "Entry state";
          break;
        case 2:
          label = "Bid Equal to Ask and Amount is equal  - no Price adjustments";
          break;
        case 3:
          label = "Bid Equal to Ask and Ask Amount higher than Bid Amount - no Price adjustments";
          break;
        case 4:
          label = "Bid Equal to Ask and Ask Amount lower than Bid Amount - no Price adjustments";
          break;
        case 5:
          label = "Cancelled";
          break;
        case 6:
        case 7:
        case 8:
        case 9:
          label = "..";
          break;
        case 10:
          label = "Bid Higher than Ask - No match conditions";
          break;
        case 11:
          label = "Bid Equal to Ask and Amount is equal  - with Bid  Price adjustments";
          break;
        case 12:
          label = "Bid Equal to Ask and Ask Amount higher than Bid Amount - with Bid  Price adjustments";
          break;
        case 13:
          label = "Bid Equal to Ask and Ask Amount lower than Bid Amount - with Bid  Price adjustments";
          break;
      }
      return label;
    },
    cancelTrade(record, index) {
      this.showLoading();
      let endPoint = process.env.VUE_APP_API_URL + "/RTAPI";
      let params = {
        Call: "CancelTrade",
        TradeID: record.ID,
        output: "json",
        MerchantID: record.MerchantID,
        APIKey: record.APIKey,
      };
      if (record.Tbl !== "TradeFlex") {
        endPoint = "https://artemis.cointopay.com/trading";
        params = {
          Call: "CancelTrade",
          TradeID: record.ID,
          Output: "json",
          MerchantID: record.MerchantID,
          APIKey: record.APIKey,
          BaseMarketID: record.Tbl === "TradeFlexDoge" ? "dog" : "erx",
        };
      }

      this.$http.get(endPoint, {
        params: params,
      }).then(response => {
        this.stopLoading();
        if (response.data?.result === "success" || response.data?.status === "success") {
          this.$toast.fire("", response.data.reason, "success");
          setTimeout(() => {
            this.$emit("update", "latest");
          }, 2000);
        } else if (response.data.result === "failed") {
          this.$toast.fire("", response.data.reason, "error");
          saveNotification(response.data.reason);
        }
      }).catch(error => {
        this.stopLoading();
        this.$toast.fire("", error.data.reason, "error");
        saveNotification(error.data.reason);
      });
    },
  },
};
</script>

<style scoped></style>

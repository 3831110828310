import { render, staticRenderFns } from "./TradeFlex.vue?vue&type=template&id=65558aff&scoped=true&"
import script from "./TradeFlex.vue?vue&type=script&lang=js&"
export * from "./TradeFlex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65558aff",
  null
  
)

export default component.exports